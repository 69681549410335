<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{$t('message.wage')}}
              </div>
              <div class="block-sarche">
                <div class="header__search my__header__search d-flex">
                  <el-input
                    placeholder="поиск"
                    prefix-icon="el-icon-search"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="sarcheBlocks"
                    size="small"
                    round
                  ></el-input>
                  <el-select
                    class="w-100"
                    v-model="form.value11"
                    :class="mode ? 'input__day' : 'input__night'"
                    placeholder="Пол"
                    size="small"
                    multiple
                  >
                    <el-option label="Jan" value="oy1"></el-option>
                    <el-option label="Feb" value="oy2"></el-option>
                    <el-option label="Mar" value="oy3"></el-option>
                    <el-option label="Apr" value="oy4"></el-option>
                    <el-option label="May" value="oy5"></el-option>
                    <el-option label="Jun" value="oy6"></el-option>
                    <el-option label="Jul" value="oy7"></el-option>
                    <el-option label="Aug" value="oy8"></el-option>
                    <el-option label="Sep" value="oy9"></el-option>
                    <el-option label="Oct" value="oy10"></el-option>
                    <el-option label="Nov" value="oy11"></el-option>
                    <el-option label="Dec" value="oy12"></el-option>
                  </el-select>
                  <el-date-picker
                    v-model="value1"
                    type="date"
                    :class="mode ? 'input__day' : 'input__night'"
                    placeholder="Месяц"
                    size="small"
                  >
                  </el-date-picker>
                  <el-date-picker
                    v-model="value1"
                    type="date"
                    :class="mode ? 'input__day' : 'input__night'"
                    placeholder="Год"
                    size="small"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="8" class="flex-style text-right">
            <!-- <el-button size="small" class="btu-color" @click="appModal = true">
              Создать
            </el-button> -->
            <el-dropdown class="setting-cheek ml-2" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
              <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  <el-checkbox
                    :checked="column.show"
                    @change="column.show = !column.show"
                    >{{ column.title }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.nameUsers.show">
                {{ columns.nameUsers.title }}
              </th>

              <th v-if="columns.workingDays.show">
                {{ columns.workingDays.title }}
              </th>

              <th v-if="columns.workingHours.show">
                {{ columns.workingHours.title }}
              </th>

              <th v-if="columns.summa.show">{{ columns.summa.title }}</th>

              <th v-if="columns.penalties.show">
                {{ columns.penalties.title }}
              </th>

              <th v-if="columns.data.show">
                {{ columns.data.title }}
              </th>

              <th v-if="columns.total.show">{{ columns.total.title }}</th>

              <!-- <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th> -->
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.nameUsers.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.nameUsers"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.nameUsers.title"
                ></el-input>
              </th>

              <th v-if="columns.workingDays.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.workingDays"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.workingDays.title"
                >
                </el-input>
              </th>
              <th v-if="columns.workingHours.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.workingHours"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.workingHours.title"
                ></el-input>
              </th>

              <th v-if="columns.summa.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.summa"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.summa.title"
                ></el-input>
              </th>
              <th v-if="columns.penalties.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.penalties"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.penalties.title"
                ></el-input>
              </th>
              <th v-if="columns.data.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.data"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.data.title"
                ></el-input>
              </th>

              <th v-if="columns.total.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.total"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.total.title"
                ></el-input>
              </th>

              <!-- <th class="settinW" v-if="columns.settings.show"></th> -->
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="user in list" :key="user.id" class="cursor-pointer">
              <td v-if="columns.id.show">{{ user.id }}</td>
              <td v-if="columns.nameUsers.show">
                {{ user.nameUsers }}
              </td>
              <td v-if="columns.workingDays.show">
                {{ user.workingDays }}
              </td>
              <td v-if="columns.workingHours.show">
                {{ user.workingHours }}
              </td>

              <td v-if="columns.summa.show">
                {{ user.summa }}
              </td>
              <td v-if="columns.penalties.show">
                {{ user.penalties }}
              </td>
              <td v-if="columns.data.show">{{ user.data }}</td>

              <td v-if="columns.total.show">
                {{ user.total }}
              </td>

              <!-- <td v-if="columns.settings.show" class="settings-td">
                <el-dropdown :hide-on-click="false" class="table-bottons-right">
                  <span class="el-dropdown-link more_icons">
                    <i class="el-icon-arrow-down el-icon-more-outline"></i>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="dropdown-menumy-style"
                  >
                    <el-dropdown-item icon="el-icon-edit">
                      Изменить
                    </el-dropdown-item>
                    <el-dropdown-item icon="el-icon-delete">
                      Удалить
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td> -->
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <Pagination />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/pagination";
import {mapGetters} from 'vuex'
export default {
  name: "wage",
  components: {
    Pagination,
  },

  data() {
    return {
      appModal: false,
      currentPage2: 5,
      checkList: [],
      form: {},
      // drawer: false,
      sarcheBlocks: "",

      filterForm: {
        id: "",
        nameUsers: "",
        workingDays: "",
        workingHours: "",
        total: "",
        data: "",
        summa: "",
        penalties: "",
      },
      list: [
        {
          id: "1",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "2",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "3",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "4",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "5",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "6",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "7",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "8",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "9",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "10",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "11",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "12",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "13",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "14",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "15",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "16",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "17",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "18",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "19",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
        {
          id: "20",
          nameUsers: "Abdullayeva Muxlisa",
          workingDays: "22",
          workingHours: "176",
          summa: "12 000 сум",
          penalties: "0",
          data: "0",
          total: "2 112 000 сум",
        },
      ],
      columns: {
        id: {
          show: true,
          title: "№",
          sortable: true,
          column: "id",
        },
        nameUsers: {
          show: true,
          title: " Ф.И.О",
          sortable: true,
          column: "nameUsers",
        },
        workingDays: {
          show: true,
          title: "Рабочие дни",
          sortable: true,
          column: "workingDays",
        },
        workingHours: {
          show: true,
          title: "Рабочие Часы ",
          sortable: true,
          column: "workingHours",
        },
        summa: {
          show: true,
          title: "Оплата за час",
          sortable: true,
          column: "summa",
        },
        penalties: {
          show: true,
          title: "Штрафы:",
          sortable: true,
          column: "penalties",
        },
        data: {
          show: true,
          title: "Сверхурочные",
          sortable: true,
          column: "data",
        },
        total: {
          show: true,
          title: "Итого",
          sortable: true,
          column: "total",
        },
        settings: {
          show: true,
          title: "Профиль",
          sortable: false,
          column: "settings",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      mode: "MODE"
  })
  }
  // methods: {
  //   openDrawer() {
  //     this.appModal = !this.appModal;
  //   },
  // },
};
</script>

